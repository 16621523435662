<template>
  <b-card
    :img-src="ownedCar.thumbnail"
    img-alt="Thumbnail"
    img-top
    tag="article"
    class="product mb-4"
    @click="$emit('selected', ownedCar)"
  >
    <p class="hover-name">{{ ownedCar.Description }}</p>
    <b-card-text>
      <strong>Item No.:</strong>
      {{ ownedCar.Item }}
      <br />
      <strong>Price:</strong>
      {{ (isCategoryA ? ownedCar.CategoryA : ownedCar.CategoryB) | amount }} /
      unit
      <br />
      <strong>Total Units:</strong>
      {{ ownedCar.Avaiable }}
      <br />
      <br />
      <!-- <strong>Colors:</strong> -->

      <b-list-group style="max-width: 300px">
        <div v-if="ownedCar.Source !== 'DealerSpecific'">
          <b-list-group-item
            style="padding: 0.35rem 1rem"
            class="d-flex align-items-center"
            v-for="(item, i) in ownedCar.ColorsAndQuantity"
            :key="i"
          >
            <span class="mr-auto">{{ item.Colour }}</span>
            <h6>
              <b-badge variant="danger">{{ item.Quantity }}</b-badge>
            </h6>
          </b-list-group-item>
        </div>
        <div v-else>
          <!-- <b-list-group-item
            style="padding: 0.35rem 1rem"
            class="d-flex align-items-center"
            v-for="(item, i) in ownedCar.AvailableColors"
            :key="i"
          >
            <span class="mr-auto">{{ item }}</span>
          </b-list-group-item> -->
        </div>
        <!-- <b-list-group-item class="d-flex align-items-center">
                    <b-avatar
                      variant="primary"
                      text="BV"
                      class="mr-3"
                    ></b-avatar>
                    <span class="mr-auto">BootstrapVue</span>
                    <b-badge>12</b-badge>
                  </b-list-group-item>-->
      </b-list-group>

      <!-- <span v-for="(item, i) in car.ColorsAndQuantity" :key="i">
                  {{ item.Colour }} - <span>{{ item.Quantity }}</span> <br />
                </span>-->
    </b-card-text>
  </b-card>
</template>

<script>
import VehicleService from "../service/vehicle";
export default {
  created() {
    this.ownedCar = this.car;
  },
  methods: {
    async visibleHandler(isVisible) {
      if (!isVisible) {
        return;
      }
      var { data } = await VehicleService.getVehicleUrl(
        this.$props.car.Description.split(" ")[0]
      );
      this.ownedCar.thumbnail = data.thumbnailLink;
      console.log(data);
    }
  },
  data() {
    return {};
  },
  props: ["car", "isCategoryA"]
};
</script>
